"use client";
import Image from "next/image";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ColorIcon, location, shopIcon } from "../../public/icons/index";
import ReserveDetails from "./ReserveDetails";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
import LoadingCard from "./LoadingCard";
import ContactSellerModal from "./ContactSellerModal";
import SignInModal from "./SignInModal";
import Link from "next/link";
import WishlistIcon from "./WishlistIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../styles/ProductCard.module.css";
import imagePlaceholder from "../../public/images/imagePlaceholder.png";
import { marketplaceCdn } from "../utility/env";
import { replaceSpecialCharacters } from "../utility/Images/utils";
import moment from "moment";
import { useUserStore } from "../store/User";
import {
  Phone_BtnClick,
  Res_btnClick,
  listing_visit,
} from "../UserEvents/UserEvents";
import Swal from "sweetalert2";
interface shopDetails {
  shop_name: string;
  owner_whatsapp_number: string;
}
interface productProp {
  recomended?: boolean;
  loading?: Boolean;
  title: String;
  price: number | string;
  condition?: string;
  discount?: number | undefined;
  item: {
    item_id?: number;
    shopTable?: any;
    location?: any;
    itemImages?: any;
    location_id?: number;
  };
  listingId: number | string;
  isRecommendationCards: boolean;
  discount_unit?: string;
  color?: string;
  effectivePrice?: number;
  isLoading?: boolean;
  activationDate?: any;
  setSignInModal: any;
  setReserveModal: any;
  setReserveData: any;
  is_featured?: boolean;
  listedQuantity?: number;
  discount_percentage?: number;
}

interface heightType {
  height: {
    delay: number;
    duration: number;
  };
}

const ProductCard = ({
  isRecommendationCards,
  item,
  loading,
  title,
  price,
  condition,
  discount,
  listingId,
  discount_unit,
  color,
  effectivePrice,
  isLoading,
  activationDate,
  is_featured,
  setReserveData,
  setReserveModal,
  setSignInModal,
  listedQuantity,
  discount_percentage,
}: productProp) => {
  // const [color,setColor]=useState()
  const shopDetails = item?.shopTable;
  const locations = item?.location;
  const image = item?.itemImages?.img0;
  const locationId = item?.location_id;
  const city = item?.location?.city?.city_name;
  const shopUsername = item?.shopTable?.username;
  const [contactModal, setContactModal] = useState(false);
  const { user, token } = useUserStore((state: any) => state);

  const router = useRouter();

  const cardsDistribution = isRecommendationCards
    ? `col g-1 g-sm-2 g-md-3 g-lg-3 g-xl-3`
    : `g-1 g-sm-2 g-md-3 g-lg-3 g-xl-3 col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3`;
  return (
    <Col className={`${cardsDistribution}`}

    >
      {/* {isLoading ? (
        <LoadingCard />
      ) : ( */}
      <Card

        className={` ${isRecommendationCards ? "h-100" : ""}  ${styles.cards} shadow-sm`}
      >
        <div className={`${styles.card} card-container position-relative`}>
          <div className="row p-0 mx-0">
            <div
              className={`position-relative ${styles.cardImgContainer}`}
              id="card-img-container"
            >
              <Link
                onClick={() => {
                  listing_visit(
                    listingId,
                    user?.uuid,
                    "PLP",
                    title,
                    `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`
                  );
                }}
                href={`/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`}
              >
                <Image
                  width={192}
                  height={0}
                  className={`p-0 w-100 ${styles.cardImg}`}
                  src={`${marketplaceCdn}/${image}`}
                  alt={`Image for ${title}`}
                // onError={(e) => (e.target.src = `${imagePlaceholder.src}`)}
                />
              </Link>
              <div className="rounded-circle text-center position-absolute bottom-0 end-0  m-0 p-0">
                <WishlistIcon
                  page="DPLP"
                  listingId={listingId}
                  title={title}
                  price={effectivePrice}
                />
              </div>
              {!!is_featured && (
                <div
                  className="text-center position-absolute top-0 start-0 d-flex justify-content-center align-items-center"
                  style={{
                    borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "0",
                    borderBottomRightRadius: "7px",
                    background: "#88C273",
                  }}
                >
                  <p
                    className="m-0 py-1 text-white fw-bold p-2"
                    style={{ fontSize: "11px", cursor: "pointer" }}
                  >
                    11.11
                  </p>
                </div>
              )}
              {(Number(discount_percentage) > 0 && effectivePrice > 0) && (
                <div
                  className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.discount}`}
                  style={{
                    // borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "7px",
                    // borderBottomRightRadius: "7px",
                    right: "0"
                  }}
                >
                  <p
                    className="m-0 py-1 text-white fw-bold p-2"
                    style={{ fontSize: "11px", cursor: "pointer", }}
                  >
                    {Number(discount_percentage)?.toFixed()}%  OFF
                  </p>
                </div>
              )}
            </div>
          </div>

          <Card.Body className="px-2 py-1 mt-0">
            <Link
              onClick={() => {
                listing_visit(
                  listingId,
                  user?.uuid,
                  "PLP",
                  title,
                  `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`
                );
              }}
              href={`/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`}
            >
              <div
                className="d-flex flex-row align-items-start"
                style={{ height: "2rem" }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip">{title}</Tooltip>}
                >
                  <>
                    <p className={`${styles.product_title} mx-1`}>
                      <span
                        className={`fw-500  ${styles.condition_container}   ${condition === "Used" ? "used" : condition === "New" ? "new" : "refurbished"}    text-white  me-1 px-1 fs-12`}

                      >
                        {condition}
                      </span>{" "}
                      {title}{" "}
                    </p>
                  </>
                </OverlayTrigger>
              </div>
            </Link>
            {effectivePrice ? (
              <>
                <Link
                  onClick={() => {
                    listing_visit(
                      listingId,
                      user?.uuid,
                      "PLP",
                      title,
                      `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`
                    );
                  }}
                  href={`/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`}
                >
                  <div
                    className="d-flex flex-row my-1 flex-wrap mx-1  "
                    style={
                      isRecommendationCards
                        ? { padding: "0px " }
                        : { padding: "7px 0 " }
                    }
                  >
                    <div
                      className={`fw-bold me-2 fw-700 ${styles.product_price_container}`}
                    >
                      {/* <span>Rs. </span> */}
                      <p className="m-0 d-inline">
                        {discount > 0 ? (
                          <div className="d-flex align-items-baseline">
                            <span className=" d-flex align-items-baseline ">
                              Rs.{effectivePrice?.toLocaleString()}
                              <div className="d-flex  justify-content-end mt-1 ">
                                {" "}
                                {discount !== 0 && (
                                  //   className={`${styles.product_discount} d-flex ms-2 text-decoration-line-through text-muted fw-500`}
                                  // >
                                  <p
                                    // style={{ fontSize: "10px" }}
                                    className={`${styles.cut_price} text-muted  text-danger fw-400  text-decoration-line-through mx-1 mb-0 `}
                                  >
                                    {price.toLocaleString()}{" "}
                                  </p>
                                )}
                              </div>
                            </span>
                            {listedQuantity <= 10 ? (
                              <span className="">
                                <p
                                  // style={{ fontSize: "10px" }}
                                  className={`text-muted mb-0 ${styles.qtyLeft} `}
                                >
                                  ({listedQuantity} Left){" "}
                                </p>
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <div className="d-flex">
                            <span>Rs.{effectivePrice?.toLocaleString()}</span>

                            {listedQuantity <= 10 ? (
                              <span className="">
                                <p
                                  // style={{ fontSize: "10px" }}
                                  className={`text-muted mb-0 ${styles.qtyLeft} ms-1 `}
                                >
                                  ({listedQuantity} Left){" "}
                                </p>
                              </span>
                            ) : null}
                          </div>
                        )}
                      </p>
                      {listedQuantity <= 10 ? (
                        <div
                          // style={{ color: "#FF4545 !important" }}
                          className={`d-flex  fw-normal ${styles.soldOut}    `}
                        >
                          {/* <img src={soldOut.src} alt="soldOut" /> */}
                          <p
                            // style={{ color: "red !important" }}
                            className={` mb-0 ${styles.soldOut}  fs-12 `}
                          >
                            Almost Sold Out
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <div className="d-flex justify-content-center mb-2 mt-2">
                <Button
                  variant="outline-secondary"
                  className=" btn-sm w-100 mx-2 rounded-3"
                  onClick={() => {
                    Phone_BtnClick(
                      shopDetails.shop_id,
                      user ? userId : null,
                      `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`,
                      title,
                      item?.location?.location_number
                    );
                    window.open(
                      `tel: ${item?.location?.location_number}`,
                      "_self"
                    );
                  }}
                >
                  Call for Price
                </Button>
              </div>
            )}

            {!isRecommendationCards && (
              <div className="mx-2 align-items-center mt-1 d-lg-flex d-md-flex d-flex justify-content-between align-item-end">
                <Link className="" href={`/store/${shopUsername}`}>
                  <img src={shopIcon.src} alt="shopIcon" />
                  <span
                    className={`${styles.store} ms-1 text-decoration-none text-nowrap`}
                  >
                    Visit Store
                  </span>
                </Link>
                <div className="">
                  <img src={location.src} alt="location" />{" "}
                  <span className={`${styles.location}`}>{city}</span>
                </div>
              </div>
            )}
            {activationDate && (
              <div className="text-center p-0 m-0 mb-1">
                <p className="m-0 p-0 text-muted fs-12">{`Last Updated: ${moment.utc(activationDate).format("MMM DD, YYYY")}`}</p>
              </div>
            )}
          </Card.Body>
        </div>
      </Card>
    </Col>
  );
};

export default ProductCard;
